<template>
  <div>
    <section class="section has-background-white-bis">
      <div class="container">
        <div class="columns">
          <div class="column is-one-fifth">
            <aside class="menu">
              <ul class="menu-list">
                <li>
                  <router-link
                    :to="{ name: 'Settings' }"
                    :class="{ 'is-active': $route.name == 'Settings' }"
                  >
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-cog"></i>
                      </span>
                      <span>Account Settings</span>
                    </span>
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'Developer' }"
                    :class="{ 'is-active': $route.name == 'Developer' }"
                  >
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-laptop"></i>
                      </span>
                      <span>Developer Tools</span>
                    </span>
                  </router-link>
                </li>

                <li v-if="user.canUseForms">
                  <router-link
                    :to="{ name: 'Inbox' }"
                    :class="{ 'is-active': $route.name == 'Inbox' }"
                  >
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-inbox"></i>
                      </span>
                      <span>Form Inbox</span>
                    </span>
                  </router-link>
                </li>
                <li v-if="user.canUseForms">
                  <router-link
                    :to="{ name: 'RequestForms' }"
                    :class="{ 'is-active': $route.name == 'RequestForms' }"
                  >
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-paperclip"></i>
                      </span>
                      <span>Request Forms</span>
                    </span>
                  </router-link>
                </li>

                <li v-if="user.subType == 'monthly' || user.subType == 'yearly'">
                  <a @click="billing()">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-credit-card"></i>
                      </span>
                      <span>Billing</span>
                    </span>
                  </a>
                </li>
                <li v-if="user.subType == 'trial' || user.subType == 'expired'">
                  <a @click="upgrade()">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-arrow-alt-circle-up"></i>
                      </span>
                      <span>Upgrade Plan</span>
                    </span>
                  </a>
                </li>
                <li>
                  <router-link
                    v-if="user.subType == 'admin'"
                    :to="{ name: 'Admin' }"
                    :class="{ 'is-active': $route.name == 'Admin' }"
                  >
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-coffee"></i>
                      </span>
                      <span>Admin</span>
                    </span>
                  </router-link>
                </li>
              </ul>
            </aside>
            <br />
            <!-- <div class="notification is-link is-light">
              <div class="content">
                <p class="has-text-weight-bold">Looking for forms?</p>
                <p>
                  Our forms API has a new home at
                  <a href="https://nextform.app">nextform.app</a>
                </p>
              </div>
            </div> -->
          </div>
          <div class="column is-four-fifths">
            <router-view v-if="!user.isLoading" :user="user" @init="$emit('init')" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.icon {
  margin-right: 8px;
}
</style> 

<script>
import { api } from '@/modules/util'

export default {
  name: 'AppParent',
  props: ['user'],
  mounted() {
    if (!this.user.id) {
      this.$emit('init')
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    async upgrade() {
      try {
        const res = await api.get('/users/upgrade')
        window.location.href = res.data.url
      } catch (err) {
        // handle error
      }
    },
    async billing() {
      try {
        const res = await api.get('/users/billing')
        window.location.href = res.data.url
      } catch (err) {
        // handle error
      }
    },
  },
}
</script>
