import { render, staticRenderFns } from "./AppParent.vue?vue&type=template&id=874fa2c2&scoped=true&"
import script from "./AppParent.vue?vue&type=script&lang=js&"
export * from "./AppParent.vue?vue&type=script&lang=js&"
import style0 from "./AppParent.vue?vue&type=style&index=0&id=874fa2c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "874fa2c2",
  null
  
)

export default component.exports